<template>
    <div>
        <div style="display: inline-block; width: 22px; vertical-align: middle;">
            <template v-if="disabled && data[field.name]">
                <font-awesome-icon :icon="['fas', 'check']" size="sm"/>
            </template>
            <template v-else-if="!disabled">
                <input type="checkbox" :id="field.name+'-'+data.id"
                       v-model="checked"
                       v-on:change="onChange"/>
            </template>
        </div>
        <div style="display: inline-block;" v-if="!field.compact && field.label"> {{ field.label }}</div>
    </div>
</template>

<script>
export default {
    name: "BoolField",
    props: ['field', 'data', 'readonly', 'state'],
    data() {
        return {
            checked: !!(this.data[this.field.name] === "0" ? false : this.data[this.field.name]),
            disabled: (
                this.readonly ||
                this.state.readonly ||
                this.state.disabled ||
                !(this.field.editintable || (this.field.editintablefield && this.data[this.field.editintablefield]))
            )
        }
    },
    methods: {
        onChange() {
            this.data[this.field.name] = this.checked ? 1 : 0;
            this.$emit('change');
        }
    }
}
</script>

<style scoped>
</style>
